/*
 * Copyright © 2017 - 2019 Atilika Inc. All rights reserved.
 */

import React, {memo} from "react";
import Button from "../../common/Button";
import Footer from "../../common/Footer";
import Intro from "../../common/Intro";
import Page from "../../common/Page";
import Meta from "../../common/Meta";
import CompanyHero from "../../company/CompanyHero";
import JoinUs from "../../company/CompanyJoinUs";
import TeamMember from "../../company/CompanyMember";
import Team from "../../company/CompanyTeam";
import {H1} from "../../common/typography/Headings";
import ChristianPhoto from "../../assets/images/team/christian.jpg";
import ErlingPhoto from "../../assets/images/team/erling.jpg";
import GautePhoto from "../../assets/images/team/gaute.jpg";
import JaredPhoto from "../../assets/images/team/jared.jpg";

const CompanyJa = () => (
    <Page lang="ja" title="Company" path="/ja/company/">
        <Meta name="description">
            私たちは東京とオスロに所在する、経験豊富のソフトウェアエンジニアです。
            全世界のお客様の難しい課題を解決するため最先端の自然言語処理、機械学習及び人工知能を活用しています。
        </Meta>
        <CompanyHero>
            <H1>企業情報</H1>
        </CompanyHero>

        <Intro title="チーム紹介">
            <p>
                私たちは東京とオスロに所在する、経験豊富のソフトウェアエンジニアです。
                全世界のお客様の難しい課題を解決するため最先端の自然言語処理、機械学習及び人工知能を活用しています。
            </p>
        </Intro>

        <Team title="チーム">
            <TeamMember
                name="クリスチャン・モエン　Christian Moen"
                title="CEO"
                photo={ChristianPhoto}
            >
                <p>
                    クリスチャンは日本企業のビジネス向上のため検索及び関連技術の導入支援を目的としてアティリカを設立しました。
                    検索エンジン開発から大手企業に置けるミッションクリティカルかつ大規模な検索ソリューションの展開など、
                    検索分野において17年以上の幅広い経験を持っています。
                </p>

                <p>
                    Apache Lucene及びApache
                    Solrのコミッタでありながら、全面的に日本語サポートのメンテナンスに携わっています。
                </p>

                <p>
                    アティリカを立ち上げる前は、FAST（現Microsoft）に10年間勤務しました。 ノルウェー
                    オスロ大学情報科学修士号取得。
                </p>
            </TeamMember>

            <TeamMember
                name="ゲーテ・ランバツェン　Gaute Lambertsen"
                title="パートナー &amp; General Manager Atilika Norway"
                photo={GautePhoto}
            >
                <p>
                    ゲーテは2012年4月にアティリカに入社しました。
                    立命館大学で移動体通信ネットワークの研究、日本科学振興機構（JST）のさきがけプロジェクトとノキアで
                    ユビキタス・コンピューティングの研究、ソニーデジタルネットワークアプリケーションズ（SDNA）でコンシューマ向けの
                    電化製品開発、ソフトウェアやコンピューティングに置いて幅広い経験を持っています。
                </p>

                <p>立命館大学情報システム学修士号取得。</p>
            </TeamMember>

            <TeamMember
                name="ジャレッド・ルイス　Jared Lewis"
                title="パートナー"
                photo={JaredPhoto}
            >
                <p>
                    ジャレッドはパートナーとして2017年にアティリカに入社しました。2011年から東京に在住しています。
                    6年以上プロフェジョナルのソフトウェア開発の経験を持って、アメリカのノースウェスタン大学の学士号を取得しています。
                </p>
            </TeamMember>

            <TeamMember
                name="アーリン・ウィシュマン・エーグ・ヘンリクセン　Erling Wishman Eeg-Henriksen"
                title="ソフトウェアエンジニア"
                photo={ErlingPhoto}
            >
                <p>
                    アーリンは東京オフィスで一年のインターンシップを経て2016年にアティリカに入社しました。
                    ノルウェーのNTNU大学から情報科学の修士号を取得し、東京工業大学で1年間留学の経験があります。
                </p>
            </TeamMember>
        </Team>
        <JoinUs title="一緒に働きませんか？">
            <p>
                アティリカでは常に才能のあるエンジニアを新しいメンバとして募集しています。
                アティリアでは協力的な楽しい開発環境、高い給料、ビザスポンサーシップなどを提供しています。
            </p>

            <p>
                東京在住または東京に来ようと考えている学生や若手の開発者のため時給ベースのインターンシップも提供しています。
            </p>

            <p>
                <Button to="/ja/careers/">もっと見る</Button>
            </p>
        </JoinUs>
        <Footer lang="ja" />
    </Page>
);

export default memo(CompanyJa);
